<template>
  <div class="illustration-info-container" v-if="imageInfo">
    <div class="illustration-info">
      <div class="content">
        <div class="w-full flex flex-col md:flex-row">
          <div class="flex-1 relative flex">
            <img style="cursor: pointer" @click="openMaxi(imageInfo)" :alt="imageInfo.headline"
              class="m-auto w-auto h-64 object-contain"
             :src="resolveImageUrl(imageInfo.ext_id, 'medium', imageInfo.html_title)" />
          </div>
        </div>

        <!-- Modal Trigger Button -->
        <div>
        <button @click="handleButtonClick(imageInfo)"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 rounded w-full md:w-56">{{ $t('buy') }} ({{ imageInfo.price | amount }})</button>
        </div>

        <div class="header p-4" style="padding: 0;">
          <h1 class="pageTitle">{{ imageInfo.headline }}</h1>
        </div>

        <div class="text-sm pageDescription" style="padding: 0;">
          <span v-html="imageInfo.aboutMotifSv"></span>
        </div>

        <div class="description" >
          <description v-model="imageInfo" />
        </div>

        <div class="tags">
          <h3 class="breadTitle">{{ $t("keyword") }}:</h3>
          <localized-link 
              v-for="(keyword, i) in sortedTags" 
              :key="i"
              v-if="keyword.titleSv" 
              :to="{ name: 'keyword', params: { keyword: keyword.htmlTitle?.toLowerCase() } }"
              class="badge badge-secondary">
              {{ keyword.titleSv }}
            </localized-link>
        </div>

        <div class="category">
          <h3>{{ $t("imageCategories") }}:</h3>
          <div v-for="(category, i) in filteredCategories" :key="i">
            <breadcrumbs v-model="filteredCategories[i]" />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="isModalVisible" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        
          <div class="header p-4">
            <p class="text-3xl">{{ $t('addedImage') }} </p>
          </div>
           <!-- <p>{{ imageInfo.headline }}</p> -->
    
        <span class="close-button" @click="closeModal">&times;</span>



        <div class="modal-butons">
          <button  @click="closeModal"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 rounded w-full md:w-56">{{ $t('continueShoping') }}
          </button>

           <!-- <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 rounded w-full md:w-56"
              @click="addToCart(imageInfo)" :disabled="!imageInfo.price">
              {{ $t('toCheckout') }} 
            </button> -->
            <localized-link
              :to="{ name: 'cartSummary' }"
              tag="button"
              @click.native="close"
             class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 rounded w-full md:w-56"
              >
               {{ $t('toCheckout') }} 
              </localized-link
            >
        </div>
     
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { orderBreadcrumbs } from '@/misc.js';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Description from './components/Description.vue';
import { resolveImageUrl } from '@/imageResolver.js'
import { i18n } from "vue-lang-router";
import { orderCategories } from "../../misc";

export default {
  name: 'illustration',

  data() {
    return {
      imageInfo: null,
      isModalVisible: false,
    };
  },

  created() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_URL}/api/ext/image/${this.$route.params.extId}`)
      .then((res) => {
        this.imageInfo = res.data.data;
        document.title = this.imageInfo.headline + " | " + i18n.t("tempus");
      });
  },

  computed: {
    sortedTags() {
      return this.imageInfo ? this.imageInfo.keywords.sort((a, b) => {
        const at = a.titleSv.toUpperCase();
        const bt = b.titleSv.toUpperCase();
        return at < bt ? -1 : at > bt ? 1 : 0;
      }) : [];
    },

    filteredCategories() {
      return this.imageInfo ? orderCategories(orderBreadcrumbs(
        this.imageInfo.categories.filter(c => c.imagesCount > 0)
      )) : [];
    },
  },

  methods: {
    handleButtonClick(imageInfo) {
      // Perform both actions here
      this.displayModal(); // Assuming displayModal is a method in your Vue component
      this.addToCart(imageInfo); // Assuming addToCart is a method in your Vue component
    },

    ...mapActions('cart', {
      addToCart: 'add',
    }),

    displayModal() {
      this.isModalVisible = true;
    },

    closeModal() {
      this.isModalVisible = false;
    },

    openMaxi(imageInfo) {
      const url = resolveImageUrl(imageInfo.ext_id, 'maxi', imageInfo.html_title);
      window.open(url, '_self');
    },

    resolveImageUrl,
  },

  components: {
    Description,
    Breadcrumbs,
  },
};
</script>

<style lang="scss" scoped>
.illustration-info-container {
  text-align: left;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.05);

  .illustration-info {
    width: 75%;
    margin: 0 auto 0;
    background-color: #fff;

    @media screen and (max-width: $bp-mobile) {
      width: 100%;
      margin: 0;
    }

    .header {
      span {
        margin: 0 1em;
        cursor: pointer;
      }

      h3 {
        padding: 1em;
      }
    }

    .content {
      div {
        padding: 1em;
      }

      .about {
        p {
          margin: 0;
          font-size: 0.9em;
          line-height: 1.4em;
        }
      }

      .category {
        div {
          padding: 0;
          margin: 1em 0;

          &:last-of-type {
            margin-bottom: 0;
            border-bottom: none;
          }
        }
      }

      .pageTitle{
        font-size: 30px;
        line-height: 36px;
        font-weight: 500;
        margin: 12px 16px;
      }

      .pageDescription{
        margin: 0 16px 12px;
        font-size: 16px;
        line-height: 29px;
        font-weight: 500;
      }

      .description {
        background-color: #dfe6e9;
        padding-left: 0;
        padding-right: 0;
        font-size: 16px !important;
        line-height: 29px;
        font-weight: 500;
      }

      .tags {
        display: block;
       

        h3{
 font-size: 16px;
        line-height: 29px;
        font-weight: 500;
        }

        .breadTitle{
          margin-bottom: 1rem;
        }

        .badge {
          display: inline-block;
          font-size: 14px;
          line-height: 20px;
          padding: 0.5em 0.75em;
          border-radius: 5px;
          color: #fff;
          margin: 0 0.5em 0.5em 0;
          background-color: #1c1c68;
        }
      }

      .category{
        h3{
 font-size: 16px;
        line-height: 29px;
        font-weight: 500;
        }
      }
    }
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
  text-align: center;

  @media only screen and (max-width: 992px) {
     max-width: 90%;
     width: 90%;
  }
}

.modal-butons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  

  @media only screen and (max-width: 600px){
    flex-direction: column;
  }
}

.close-button {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 25px;
  cursor: pointer;
}
</style>
